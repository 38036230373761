.login-box {
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: $spacing-large;
    margin: auto;

    @include media(">medium") {
        box-shadow:
        0 0.5px 2.3px rgba(0, 0, 0, 0.031),
        0 1.4px 6.4px rgba(0, 0, 0, 0.045),
        0 3.3px 15.4px rgba(0, 0, 0, 0.059),
        0 11px 51px rgba(0, 0, 0, 0.09)
      ;
    }
}
