.pagination {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: center;
    font-size: $font-xsmall;
    line-height: 1;

    a {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        color: $cb;
        border: 1px solid $cb;
        border-radius: $border-radius;
        white-space: nowrap;
    }

    li {
        margin: 0 $spacing-xsmall $spacing-xsmall 0;

        &.active {
            a {
                background: $cb;
                color: #fff;
            }
        }

        &.disabled {
            a {
                color: $gray-300;
                border-color: $gray-300;
            }
        }

        &:not(.disabled):not(.active) {
            a {
                &:hover,
                &:focus {
                    // background: $gray-100;
                    box-shadow: 0 0 0 2px $gray-300;
                }
            }
        }
    }
}
