.aside-box {
    width: 100%;
    margin-bottom: $spacing-normal;
    padding: 0 $spacing-normal $spacing-normal;
    background: $gray-100;

    $this: &;

    &--scroll {
        #{$this}__content {
            max-height: 300px;
            overflow-y: auto;
        }
    }

    $this: &;

    &__title {
        font-weight: bold;
        color: $gray-800;
        border-bottom: 1px solid $gray-300;
        padding: $spacing-normal 0 $spacing-xsmall 0;
        margin-bottom: $spacing-normal;
        font-size: $font-normal;
    }

    &__content {
        margin-top: $spacing-normal;

        ul {
            list-style-type: none;
            padding-left: 0;
            margin: 0;
            line-height: 1.2;
            font-size: $font-small;

            li {
                margin-bottom: $spacing-small;
            }
        }

        h3 {
            color: currentColor;
            font-weight: normal;
            font-size: $font-small;
            margin: 0;
        }
    }

    a {
        color: $color-text;

        &:hover {
            text-decoration: underline;
            color: $cb;
        }
    }
}
