.mb-0 {
    margin-bottom: 0;
}
.pt-0 {
    padding-top: 0;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.d-inline-block {
    display: inline-block;
}
.color-brand {
    color: $cb !important;
}

.d-none {
    display: none;
}

.float-right {
    float: right;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
