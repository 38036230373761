.product-price {
    margin-bottom: $spacing-small;
    // font-size: $font-small;
    line-height: 1;

    &__cut {
        display: flex;
    }

    &__old {
        text-decoration: line-through;
        margin-right: $spacing-small;
        padding-bottom: $spacing-small;
    }

    &__save {
        color: $gray-500;
    }

    &__main {
        font-size: $font-xxlarge;
        color: $cb;
        font-weight: 600;

        sup {
            font-size: 50%;
        }
    }
    &__unit {
        font-weight: normal;
        color: $cb-gray;
        font-size: $font-small;
    }
}
