.homepage-slider-container {
    display: flex;
    align-items: center;
    background: $gray-300;
    overflow: hidden;

    @include media(">large") {
        height: 430px;
        padding-left: 260px;
        overflow-y: hidden;
    }

    .tns-outer {
        position: relative;
    }

    .tns-nav {
        position: absolute;
        z-index: 1;
        bottom: $spacing-normal;
        right: $spacing-normal;
        display: flex;

        button {
            border: 0;
            outline: 0;
            width: 30px;
            height: 4px;
            background: $cb-lightgray;
            margin-left: $spacing-small;
            box-shadow: 1px 1px 3px rgba(black, 0.4);

            &:not(.tns-nav-active):hover,
            &:not(.tns-nav-active):active {
                background: #fff;
            }
        }

        .tns-nav-active {
            background: $cb;
        }
    }
}

.homepage-slider {
    width: 100%;
    height: 100%;

    > div {
        position: absolute;
        opacity: 0;
        transition: all 0.3s ease;
    }

    &.initialized {
        > div {
            position: initial;
            opacity: 1;
        }
    }
}

.homepage-features {
    background: #dee2e68c;
    padding: $spacing-normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    overflow-x: auto;
    margin-bottom: 0;

    @include media(">large") {
        padding: $spacing-large;
    }
}

.homepage-feature {
    display: flex;
    flex-shrink: 0;
    padding: 0 $spacing-small;
    font-size: $font-small;

    svg {
        display: block;
        max-width: 40px;
        max-height: 40px;
        margin-right: $spacing-small;
        object-position: right center;
        flex-shrink: 0;
    }
    span {
        flex-shrink: 0;
    }
}

.product-item-slider {
    position: relative;
    padding-bottom: $spacing-large;

    > div {
        display: flex;
    }

    .tns-outer {
        display: block;
        width: 100%;
    }

    .tns-slider {
        display: flex;
    }

    .tns-ovh {
        margin-bottom: $spacing-normal;
        padding-bottom: $spacing-small;
        padding-top: $spacing-small;
    }

    .tns-controls {
        button {
            display: block;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;
            text-indent: -99999px;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            background: #fff;
            color: #fff;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease;
            border: 1px solid $gray-300;
            z-index: 3;

            &:hover {
                box-shadow: 0 0 5px rgba(black, 0.1);
                &:before {
                    color: $gray-600;
                }
            }

            &[disabled] {
                opacity: 0.3;
            }

            &:active,
            &:focus {
                outline: 0;
            }

            &:before {
                content: "";
                // display: block;
                color: $color-text;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-indent: 0;
                font-size: 18px;
                font-weight: bold;
                color: $gray-400;
            }

            &[data-controls="prev"] {
                left: -#{$spacing-xsmall};

                @include media(">medium") {
                    left: -40px;
                }

                &:before {
                    content: "\276E";
                }
            }

            &[data-controls="next"] {
                right: -#{$spacing-xsmall};

                @include media(">medium") {
                    right: -40px;
                }

                &:before {
                    content: "\276F";
                    // border-left-color: transparent;
                    // border-bottom-color: transparent;
                    // transform: rotate(45deg);
                }
            }
        }
    }

    .slider-item {
        padding: 0 4px;
        width: 190px;
        display: inline-block;
        visibility: hidden;
        transition: all 0.3s ease;
        opacity: 0;
        transition-delay: 0.3s;
        display: inline-flex !important;

        &.tns-item {
            visibility: visible;
            opacity: 1;
        }

        @include media(">=medium") {
            width: calc(1200px / 6);
        }
    }

    .tns-nav {
        display: flex;
        justify-content: center;

        button {
            border: 0;
            outline: 0;
            width: 30px;
            height: 4px;
            background: $cb-lightgray;
            margin-left: $spacing-small;

            &:not(.tns-nav-active):hover,
            &:not(.tns-nav-active):active {
                background: #fff;
            }
        }

        .tns-nav-active {
            background: $cb;
        }
    }

    .list-item {
        width: 100%;
        margin: 0;
    }
}
