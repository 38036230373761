.newsletter-signup {
    min-height: 200px;
    margin-bottom: $spacing-xlarge;
    padding: $spacing-normal;
    line-height: 1.2;
    background: linear-gradient(to right, $cb 0%, $cb-orange 100%);

    &__content {
        color: #fff;
        font-size: $font-large;
    }

    h2 {
        color: currentcolor;
        margin-bottom: $spacing-small;
    }

    form {
        margin: 0;

        button {
            white-space: nowrap;
        }
    }

    input {
        margin-bottom: $spacing-small;
    }

    .form-group {
        margin-bottom: 0;
    }

    .form-error {
        background: $cb;
        color: #fff;
        padding: $spacing-xsmall;
    }

    @include media(">medium") {
        display: flex;
        background: url(/img/bg-newsletter.png) no-repeat right center / 40% 100%,
            linear-gradient(to right, $cb 0%, $cb-orange 100%);
        align-items: center;

        &__content {
            max-width: 60%;
            padding: $spacing-large;
        }

        h2 {
            font-size: $font-xxxlarge;
        }

        form {
            display: flex;
        }

        input {
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 0;
            margin-right: $spacing-normal;
        }
    }
}
