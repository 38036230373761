.category-page {
    display: flex;
}

.col-left {
    width: 230px;
    flex-shrink: 0;
    margin-right: 20px;

    @include media("<medium") {
        position: absolute;
        left: -9999px;
    }

    @include media(">=medium") {
        display: block;
        position: static;
        left: auto;
    }
}
.col-right {
    width: 100%;

    @include media(">=medium") {
        width: calc(100% - 250px);
    }
}

.category-toc {
    background: $gray-300;
    padding: $spacing-normal;
    margin-bottom: $spacing-normal;
    font-size: $font-small;

    &__title {
        margin-bottom: $spacing-normal;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li:not(:last-child) {
            ul {
                margin-bottom: $spacing-normal;
            }
        }
    }

    a {
        color: $color-text;

        &:hover {
            color: $cb;
            text-decoration: underline;
        }
    }
}

.category-header {
    margin-bottom: $spacing-small;
    padding: $spacing-small;
    background: #fff;
    margin-left: -#{$spacing-small};
    margin-right: -#{$spacing-small};

    @include media(">=medium") {
        margin-bottom: $spacing-normal;
        padding: $spacing-normal;
        margin-left: 0;
        margin-right: 0;
    }

    &__title {
        padding-bottom: $spacing-small;
        border-bottom: 1px solid $gray-300;
        margin-bottom: $spacing-normal;

        span {
            font-size: $font-normal;
            color: $gray-600;
            font-weight: normal;
            // color: $gray-800;
        }
    }

    &__actions {
        font-size: $font-small;

        .form-inline {
            display: flex;
            justify-content: space-between;

            @include media("<large") {
                position: sticky;
                top: $spacing-xsmall;

                label {
                    display: none;
                }
            }
        }
    }

    &__filter-trigger {
        display: block;
        margin-right: 0 !important;
        width: calc(50% - #{$spacing-small});

        @include media(">=medium") {
            display: none;
        }
    }

    &__products {
        display: none;
        @include media(">=medium") {
            display: block;
        }
    }

    &__order {
        margin-right: 0 !important;
        width: calc(50% - #{$spacing-small});

        .form-control {
            width: 100%;
        }

        label {
            display: none;
        }

        @include media(">=medium") {
            width: auto;

            label {
                display: inline-block;
            }

            .form-control {
                width: auto;
            }
        }
    }

    &__short-description {
        margin-bottom: $spacing-normal;
        font-size: $font-small;

        > div,
        p {
            display: inline;
            margin: 0;
        }
    }
}

.category-banner {
    position: relative;
    height: 0;
    padding-top: calc(100% / 9.5);
    // margin-top: $spacing-normal;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
}

.filters-container {
    @include media("<medium") {
        display: none;
        padding: $spacing-small;
        background: $gray-100;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 80px;
        z-index: 12;

        &.active {
            display: block;
        }
    }
}

.filters-reset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-text;
    padding: $spacing-small;

    &:hover {
        color: $cb;
    }

    path {
        fill: $cb;
    }

    @include media(">=medium") {
        padding: 0;
    }
}

.filter-box {
    width: 100%;
    margin-bottom: $spacing-normal;
    font-size: $font-normal;
    padding: 0 $spacing-small;

    $this: &;

    &__header {
        background: #fff;
        padding: $spacing-small;
        border-bottom: 1px solid $gray-500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media("<medium") {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: #fff;
            z-index: 10;
        }
    }

    &__end {
        text-align: center;
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: all 0.2s ease-in-out;

        svg {
            width: 18px;
            height: 18px;
        }

        path {
            fill: $gray-600;
        }
    }

    &__title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $font-normal;
        font-weight: bold;
        color: $gray-800;

        @include media("<medium") {
            padding-bottom: $spacing-normal;
        }
    }

    &__content {
        padding-bottom: $spacing-normal;

        @include media(">=medium") {
            margin-top: $spacing-normal;
            padding-bottom: 0;
            max-height: 260px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: $gray-100;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $gray-500;
            }
        }

        .rangeslider {
            padding-top: $spacing-small;
            padding-bottom: 0;
            margin: 0 $spacing-xsmall;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin: 0;
            line-height: 1.2;

            li {
                margin-bottom: $spacing-small;
            }
        }
    }

    label {
        display: block;
        width: 100%;
        margin-bottom: $spacing-small;
        cursor: pointer;

        @include media(">=medium") {
            margin-bottom: $spacing-xsmall;
        }

        span {
            color: $gray-500;
        }
    }

    &.collapsed {
        #{$this}__toggle {
            transform: scaleY(-1);
        }
    }

    @include media("<medium") {
        border-bottom: 1px solid $gray-500;
    }

    @include media(">=medium") {
        padding: $spacing-normal;
        background: #fff;
        font-size: $font-small;

        &__header,
        &__end {
            display: none;
        }
    }
}

.category-list {
    display: flex;
    margin: 0 -#{$spacing-small} $spacing-normal -#{$spacing-small};
    flex-wrap: wrap;
    // justify-content: space-between;
}

.category-list-footer {
    background: #fff;
    padding: $spacing-normal;
    margin-bottom: $spacing-large;

    p {
        margin-bottom: 0;
    }

    &__desktop {
        display: flex;
        justify-content: space-between;
        align-content: center;
        display: none;

        @include media(">medium") {
            display: flex;
            align-items: center;

            .pagination {
                margin-bottom: -#{$spacing-xsmall};
            }
        }
    }

    &__mobile {
        font-size: $font-small;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-weight: bold;
        }

        .btn {
            margin-left: $spacing-normal;
        }

        p {
            white-space: nowrap;
        }

        .pagination {
            margin-bottom: 0;
            padding-left: $spacing-normal;
        }

        @include media(">medium") {
            display: none;
        }
    }
}

.list-item {
    background: #fff;
    padding: $spacing-small;
    margin: $spacing-xsmall;
    position: relative;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
    width: calc(100% / 2 - 2 * #{$spacing-xsmall});

    @include media(">medium") {
        width: calc(100% / 3 - 2 * #{$spacing-xsmall});
    }

    @media (min-width: 1200px) {
        width: calc(100% / 4 - 2 * #{$spacing-xsmall});
    }

    .btn--cart {
        min-height: 30px;

        &:before {
            height: 30px;
        }
    }

    .product-return-days {
        color: $cb;
        border: 1px solid $cb;
        text-align: center;
        margin: 5px auto;
        padding: 5px;
        font-size: $font-small;
    }

    &__flags {
        position: absolute;
        left: -#{$spacing-xsmall};
        top: $spacing-normal;
        z-index: 1;

        span {
            display: block;
            width: fit-content;
        }

        &:not(:empty) {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 $spacing-xsmall $spacing-xsmall 0;
                border-color: transparent $gray-500 transparent transparent;
            }
        }
    }

    &__admin {
        position: absolute;
        right: $spacing-xsmall;
        top: $spacing-large;
        z-index: 1;
    }

    &__discount {
        background: $cb;
        color: #fff;
        padding: $spacing-xsmall $spacing-small;
        font-weight: 400;
        position: relative;
        font-size: 11px;
    }

    &__discount-black-friday {
        background: black;
        color: #fff;
        padding: $spacing-xsmall $spacing-small;
        font-weight: 600;
        font-size: 11px;
        position: relative;
    }

    &__free-shipping {
        background: linear-gradient(to right, $cb-dark, $cb);
        color: #fff;
        padding: $spacing-xsmall $spacing-small;
        font-weight: 400;
        font-size: 11px;
        text-transform: uppercase;
    }

    &__fav {
        position: absolute;
        top: $spacing-small;
        right: $spacing-xsmall;
        cursor: pointer;
        z-index: 1;

        svg.off {
            fill: $color-text;
        }
        svg.on {
            fill: $cb;
        }

        &:hover {
            svg.off {
                fill: $cb;
            }
        }
    }

    &__image {
        width: 100%;
        height: 0;
        position: relative;
        padding-top: 100%;
        margin-bottom: $spacing-xsmall;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
            object-position: center;
            padding: 0 $spacing-normal;
        }
    }

    &__rating {
        margin-bottom: $spacing-xsmall;
        display: flex;
        justify-content: center;

        .product-reviews {
            margin: 0 auto;
            font-size: $font-xsmall;
        }
        .product-rating {
            &__bg {
                font-size: $font-small;
            }

            &__num {
                color: $gray-500;
            }

            &__reviews {
                color: $gray-500;
            }
        }
    }

    &__title {
        margin-bottom: $spacing-xsmall;
        font-size: 16px;
        text-align: center;
        height: 55px;
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-weight: bold;
        overflow: hidden;

        a {
            color: $gray-900;
        }
    }

    &__variants {
        margin-bottom: 0;
        text-align: center;
        height: 28px;
        overflow: hidden;
        font-size: $font-xsmall;
        margin-bottom: $spacing-xsmall;

        a {
            color: $gray-500;
        }
    }

    &__stock {
        text-align: center;
        font-weight: 600;
        font-size: $font-xsmall;
        margin-bottom: $spacing-xsmall;
        margin-top: auto;
    }

    &__price {
        text-align: center;
        margin-bottom: $spacing-xsmall;
        margin-top: auto;

        .product-price {
            margin-bottom: 0;

            &__cut {
                justify-content: center;
                font-size: $font-xsmall;
            }
            &__main {
                font-size: $font-normal;
            }
        }
    }

    &__actions {
        text-align: center;
        padding-top: $spacing-xsmall;
        font-size: $font-small;

        a + a {
            margin-top: $spacing-small;
        }
    }

    &:hover {
        box-shadow: 0 0 10px rgba(black, 0.2);
    }
}

.category-list {
    display: flex;
    margin-bottom: $spacing-xlarge;
}

.category-item {
    background: #fff;
    padding: $spacing-small;
    margin: $spacing-small;
    position: relative;
    line-height: 1.2;
    text-align: center;
    transition: all 0.2s ease;
    width: 100%;
    font-size: $font-xsmall;
    margin-bottom: $spacing-normal;

    @include media(">medium") {
        width: calc(100% / 2 - 2 * #{$spacing-small});
    }

    @media (min-width: 1200px) {
        width: calc(100% / 3 - 2 * #{$spacing-small});
    }

    &__img {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;
        margin-bottom: $spacing-normal;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            place-content: center;
        }
    }
    &__title {
        margin-bottom: $spacing-xsmall;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(black, 0.2);
    }
}
