.rangeslider {
    // margin-top: $spacing-normal;
    // margin-bottom: $spacing-large;
    padding: $spacing-normal $spacing-small $spacing-normal $spacing-small;
}

.noUi-target {
    height: 10px;
    box-shadow: none;
    border-radius: $border-radius;
    border: none;
}

.noUi-connects {
    border-radius: $border-radius;
    background: $gray-500;
    height: 4px;
    // background: $cb;
}
.noUi-horizontal {
    .noUi-handle {
        width: 20px;
        height: 20px;
        border-radius: 18px;
        top: -8px;
        right: -10px;
        background: $cb;
        box-shadow: 1px 1px 3px rgba(black, 0.2);
        outline: 0;
        cursor: pointer;
        border: none;

        &:before,
        &:after {
            display: none;
        }
    }
}

.rangeslider-value {
    text-align: right;
    font-size: $font-small;
}
