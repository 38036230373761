.error-page {
    h1 {
        font-size: 15vw;
        font-weight: bold;
        color: $cb;

        @include media("<medium") {
            font-size: 80px;
        }
    }
    @include media("<medium") {
        font-size: $font-large;
    }
}
