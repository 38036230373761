.vreausavand {
    background: $gray-200;
    padding: $spacing-xlarge 0;
    margin-bottom: $spacing-xxlarge;

    &__title {
        text-align: center;
    }
    &__steps {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media(">=medium") {
            flex-direction: row;
        }
    }

    svg {
        max-width: 100%;
        height: 100px;
        object-position: bottom center;
    }

    &-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        word-break: keep-all;
        padding: 0 $spacing-small;

        p {
            margin-bottom: 0;
            text-align: center;
        }

        svg {
            margin-bottom: $spacing-small;
        }

        @include media(">=medium") {
            font-size: $font-xlarge;

            svg {
            }
        }

        &__arrow {
            display: flex;
            align-items: center;
            max-width: 30px;
            transform: rotate(90deg);

            @include media(">=medium") {
                max-width: 150px;
                transform: none;
            }
        }
    }
}

.sale-steps {
    counter-reset: step-counter;
    margin-bottom: $spacing-large;
    margin-top: $spacing-xxlarge;

    @include media(">=medium") {
        display: flex;
        flex-direction: column;
    }
}
.sale-step {
    $counter-size: 60px;
    background: $gray-300;
    counter-increment: step-counter;
    position: relative;
    margin: 0 auto $spacing-xlarge auto;

    &:before {
        content: counter(step-counter);
        width: $counter-size;
        height: $counter-size;
        position: absolute;
        top: calc(-#{$counter-size} / 2);
        left: calc(50% - #{$counter-size} / 2);
        border-radius: 100%;
        background: $cb;
        color: #fff;
        font-size: $font-xlarge;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-weight: 600;
        border: 5px solid #fff;
    }

    @include media(">=medium") {
        width: 100%;
        display: flex;
        max-width: 600px;
        transform: translateX(-50px);

        &:nth-child(even) {
            transform: translateX(50px);
        }

        &:before {
            left: calc(-#{$counter-size} / 2);
            top: calc(50% - #{$counter-size} / 2);
        }
    }

    &__icon {
        background: $cb-gray;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: $spacing-xlarge $spacing-small $spacing-normal;

        svg {
            display: block;
            max-width: 60px;
            width: 100%;
            height: 100%;

            * {
                fill: #fff;
            }
        }

        @include media(">=medium") {
            border-radius: $border-radius 0 0 $border-radius;
            max-width: 200px;

            svg {
                max-width: 80px;
            }
        }
    }

    &__text {
        padding: $spacing-normal;
        border-radius: 0 $border-radius $border-radius 0;

        ul {
            list-style-type: square;
            margin-bottom: 0;
            line-height: 1;

            li {
                margin-bottom: $spacing-small;
            }
        }
        @include media(">=medium") {
            padding: $spacing-large;
        }
    }
}
.vreausavand-advantages {
    margin-left: auto;
    margin-right: auto;
    font-size: $font-large;
    width: fit-content;
}
.vreausavand-form {
    background: url(/img/bg-cta.svg) no-repeat bottom center / cover;
    color: #fff;
    margin: $spacing-xlarge 0;
    text-align: center;
    padding: $spacing-xlarge $spacing-large;
    margin-left: -#{$spacing-small};
    margin-right: -#{$spacing-small};
    font-size: $font-large;

    @include media(">=medium") {
        padding: $spacing-xlarge;
    }

    form {
        max-width: 600px;
        text-align: center;
        margin: 0 auto;
    }

    &__container {
        max-width: 600px;
        margin: auto;

        .form-control {
            margin-bottom: $spacing-small;
        }

        @include media(">=medium") {
            display: flex;

            .form-control {
                margin-bottom: 0;
            }

            h1 {
            }
        }
    }

    h1 {
        color: #fff;
        margin-bottom: $spacing-normal;
    }

    @include media(">=medium") {
        h1 {
            font-size: $font-xxxlarge;
        }
    }
}
