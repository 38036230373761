.blog-page {
    &__main {
        width: 100%;
    }

    &__list {
        padding: $spacing-normal;
        background: #fff;
        margin-bottom: $spacing-normal;
    }

    @include media(">=medium") {
        display: flex;

        &__list,
        &__article {
            // margin-right: $spacing-xxlarge;
            padding: $spacing-xlarge;
        }

        &__aside {
            width: calc(100% / 4);
            margin-left: $spacing-normal;
        }
    }
}

.blog-item {
    display: flex;

    $this: &;

    &:not(:last-of-type) {
        margin-bottom: $spacing-xlarge;
    }

    a {
        color: $color-text;
    }

    &__meta {
        p {
            transition: color 0.3s ease;
        }
    }

    &__image {
        width: 100%;
        max-width: 160px;
        height: 0;
        padding-top: 100px;
        position: relative;
        margin-right: $spacing-large;
        overflow: hidden;

        img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-position: center;
            object-fit: contain;
        }
    }

    &:hover {
        #{$this}__meta {
            p {
                color: $cb;
            }
        }

        #{$this}__image {
            img {
                box-shadow: 2px 2px 10px rgba(black, 0.05);
            }
        }
    }
}
