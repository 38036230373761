.cart-page {
    @include media(">medium") {
        display: flex;

        .cart-left {
            flex: 1;
        }

        .cart-right {
            flex: 0 0 280px;
            margin-left: $spacing-large;
        }
    }
}

.cart-summary {
    position: sticky;
    background: $gray-200;
    padding: $spacing-normal;
    top: $spacing-normal;

    &__row {
        display: flex;
        justify-content: space-between;

        span {
            flex: 1;
        }

        span:last-child {
            font-weight: bold;
            text-align: right;
        }
    }
    &__shipping-row {
        font-size: $font-small;
        display: grid;
        grid-template-columns: 5fr 8fr;
        gap: $spacing-normal;
        margin-bottom: $spacing-small;

        span:first-child {
            white-space: nowrap;
        }

        span:last-child {
            font-weight: 600;
            text-align: right;
        }
    }

    &__total {
        font-weight: 600;
        white-space: nowrap;
        color: $gray-900;
        margin-top: $spacing-normal;
        line-height: 1.2;

        span {
            display: block;

            &:last-child {
                font-weight: 900;
                font-size: $font-xxlarge;
            }
        }
    }
}

.cart-group {
    background: $gray-200;
    padding: $spacing-normal $spacing-small;
    margin-bottom: $spacing-normal;
    border-radius: $border-radius;

    &__totals-row {
        color: $gray-800;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: auto;
        gap: $spacing-normal;
        width: 100%;
        font-size: $font-small;

        &.last {
            font-size: $font-normal;
            padding-top: $spacing-xsmall;
        }

        &:first-of-type {
            padding-top: $spacing-small;
        }

        span:first-child {
            //flex: 1;
            width: 55%;
        }
    }

    @include media(">=medium") {
        padding: $spacing-normal;
    }

    @include media(">small") {
        &__totals-row {
            max-width: 300px;
        }
    }
}

.cart-item {
    background: #fff;
    padding: $spacing-normal;
    margin-bottom: $spacing-xsmall;

    a {
        color: $color-text;

        &:hover {
            color: $cb;
        }
    }

    &__image {
        width: 80px;
        margin-bottom: $spacing-small;
        flex-shrink: 0;

        img {
            width: 100%;
            display: block;
        }
    }

    &__amount {
        font-size: $font-small;
    }

    &__delete {
        a {
            color: $cb;
        }
    }

    &__um {
        font-size: $font-xsmall;
        font-weight: 600;
    }

    &__price {
        text-align: right;

        .product-price {
            &__main {
                font-size: $font-large;
            }

            &__old {
                margin-right: 0;
                padding-bottom: 0;
            }

            &__cut {
                display: block;
                font-size: $font-xsmall;
            }
        }
    }

    @include media(">=medium") {
        display: flex;
        align-items: center;

        &__image {
            width: 60px;
            margin-bottom: 0;
        }

        &__meta {
            padding: 0 $spacing-normal;
            flex-grow: 1;
        }

        &__stock {
            margin-bottom: 0;
        }

        &__amount {
            text-align: center;
            width: 20%;
        }

        &__price {
            width: 15%;
            flex-shrink: 0;
            text-align: right;
            flex-grow: 0;
        }

        &__title {
            margin-bottom: 0;
        }
    }
}

.cart-actions {
    @include media("<medium") {
        .btn {
            width: 100%;
            margin-top: $spacing-normal;
        }
    }
}

.quantity-selector {
    display: inline-flex;
    border: 1px solid #ccc;
    border-radius: 3px;
    overflow: hidden;
    min-height: 36px;

    span {
        display: flex;
        background: #e5e5e5;
        padding: 0 10px;
        margin-left: 1px;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease;

        &:active,
        &:hover {
            background: #666;
            color: #fff;
        }
    }

    input {
        width: 70px;
        text-align: center;
        border: 0;
    }

    .btn {
        line-height: 1;
        font-size: $font-large;
        font-weight: 600;
    }

    &__delete {
        display: block;
        text-align: center;
        width: 100%;
        color: $color-text;
        padding-top: 10px;
        cursor: pointer;

        &:hover {
            color: $cb;
            text-decoration: underline;
        }
    }
}

.modal-add-to-cart {
    // display: none;
    width: 100%;
}

.cart-product {
    margin-bottom: $spacing-normal;

    &__img {
        max-width: 60px;
        margin-bottom: $spacing-small;
    }

    &__title {
        flex-grow: 1;
        margin-bottom: $spacing-normal;
    }

    &__price {
        margin-bottom: $spacing-small;

        .product-price {
            white-space: nowrap;
            margin-bottom: 0;

            &__cut {
                font-size: $font-small;
            }

            &__main {
                font-size: $font-large;
            }
        }
    }

    &__actions {
        white-space: nowrap;
    }

    @include media(">=medium") {
        margin-bottom: $spacing-large;
        display: flex;
        align-items: center;

        &__img {
            margin-bottom: 0;
        }

        &__title {
            max-width: 400px;
            padding: 0 $spacing-normal;
            margin-bottom: 0;
        }

        &__price {
            margin-left: auto;
            margin-bottom: 0;
        }

        &__actions {
            padding-left: $spacing-large;
        }
    }
}

.modal-product-slider {
    background: $gray-200;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -15px;
    padding: $spacing-large $spacing-small $spacing-normal $spacing-small;

    .product-item-slider {
    }

    @include media(">=medium") {
        padding: $spacing-large $spacing-xlarge 0 $spacing-xlarge;
    }
}

.checkout-summary-item {
    padding: $spacing-small 0;

    &:not(:last-child) {
        border-bottom: 1px solid $gray-400;
    }
    span {
        display: block;
    }

    span:last-child {
        text-align: right;
        padding-top: $spacing-small;
    }

    @include media(">=medium") {
        align-items: center;
        display: flex;
        justify-content: space-between;

        span:last-child {
            padding-top: 0;
        }
    }
}
