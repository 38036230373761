.my-account {

    &__left {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            margin-top: $spacing-small;
    
            li {
                margin-bottom: $spacing-xsmall;

                a {
                    color: $color-text;
                }

                &:last-child {
                    a  {
                        color: $cb;
                    }
                }
            }
        }
    }


    @include media(">medium") {
        display: flex;
        width: 100%;

        &__left {
            width: calc(100% / 5);
            margin-right: $spacing-large;
        }

        &__right {
            flex: 1;
        }
    }
}

.user-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing-normal;

    .avatar {
        margin-bottom: 0;
        text-align: center;
        overflow: visible;
        width: 100px;
        height: 100px;
        flex-shrink: 0;

        &__edit {
            position: absolute;
            bottom: 0;
            right: 0;
            background: $cb;
            color: #fff;
            z-index: 1;
            font-size: $font-xsmall;
            padding: 0 $spacing-xsmall;
        }
        
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: center;
        padding-top: $spacing-small;

        span {
            display: none;
        }


        @include media(">=medium") {
            text-align: left;
            padding: initial;
            padding-top: 0;

            span {
                display: inline-block;
            }
            min-width: 80px;
        }

        li {
            margin: $spacing-xsmall 0;
        }
    }

    @include media(">=medium") {
        flex-direction: row;

        .avatar {
            margin-right: $spacing-normal;
        }
        
    }

}


.user-activity {
    
    &-item {
        
        &__title {
            font-weight: 600;
            display: block;
        }
    }
    
    @include media(">=medium") {
        display: flex;
        justify-content: space-between;

        &-item {
            display: block;
            width: calc(100% / 3 - 15px);
        }    
    }

}


.order-item {
    width: 100%;
    margin-bottom: $spacing-normal;
    padding: $spacing-small;
    background: $gray-200;
    border-radius: $border-radius;
    align-items: center;



    &__data {

        p {
            margin-bottom: $spacing-small;
        }
    }

    &__actions {

        .btn {
            margin-bottom: $spacing-xsmall;
        }
    }

    @include media(">=medium") {
        display: flex;
        justify-content: space-between;
        &__actions {
            text-align: center;
        }        
    }
}



.address-item {
    padding-bottom: $spacing-normal;
    margin-bottom: $spacing-normal;
    border-bottom: 1px solid $gray-400;
    
    @include media(">=medium") {
        align-items: center;
        display: flex;
        justify-content: space-between;

    }
}