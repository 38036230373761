.breadcrumb {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 13px;
    width: 100%;

    li {
        display: flex;
        position: relative;
        padding: 0 $spacing-small;
        height: 28px;
        align-items: center;

        &.separator {
            padding: 0;
        }
    }

    a {
        text-decoration: none;
        color: $cb;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media("<=medium") {
        overflow-x: scroll;
        white-space: nowrap;
        border-bottom: 1px solid $gray-300;

        li {
            padding: 0 $spacing-normal 0 $spacing-small;

            &.separator {
                display: none;
            }

            &:not(:last-child) {
                &:before,
                &:after {
                    content: "";
                    display: block;
                    height: 50%;
                    width: 1px;
                    background: $gray-400;
                    position: absolute;
                    right: 0;
                }

                &:before {
                    top: 0;
                    transform-origin: bottom left;
                    transform: skewX(30deg);
                }

                &:after {
                    top: 50%;
                    transform-origin: top left;
                    transform: skewX(-30deg);
                }
            }

            &:active {
                a {
                    text-decoration: underline;
                    color: $cb;
                }
            }
        }

        a {
            color: $color-text;
        }
    }

    @include media(">medium") {
        padding: $spacing-small 0;

        li {
            padding: 0;

            &.separator {
                padding: 0 $spacing-small;
            }
        }
    }
}
