.form-label {
    font-size: 13px;
    font-weight: 600;
    color: #222;
    margin-bottom: 0;
}
.required {
    &:after {
        content: "*";
        font-family: "Montserrat";
        font-weight: 600;
        color: $cb;
    }
}
.message {
    margin-bottom: $spacing-normal;
    font-weight: bold;
    padding: $spacing-small;
    border: 1px solid #ccc;

    &--error {
        color: $cb;
        border-color: $cb;
    }

    &--success {
        color: $cb-green;
        border-color: $cb-green;
    }
}

.form-group {
    margin-bottom: $spacing-normal;
}

.form-inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .form-group {
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: $spacing-normal;
        }
    }

    .form-control {
        display: inline-flex;
        width: auto;
    }
    .form-label {
        margin-right: $spacing-xsmall;
    }
}

.form-control {
    height: 36px;
    padding: $spacing-xsmall $spacing-small;
    display: flex;
    width: 100%;
    align-items: center;
    outline: 0;
    border: 1px solid $gray-400;
    // border-radius: $border-radius;
    font-family: $font-family-main;
    font-size: $font-normal;
    color: $color-text;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus {
        box-shadow: 0 0 0 3px $gray-300;
    }
}
textarea.form-control {
    height: initial;
}

textarea {
    resize: vertical;
}

.form-error {
    display: block;
    color: tomato;
    font-weight: 600;
    font-size: 12px;
    padding-top: 3px;
    background: #fff;

    &:empty {
        display: none;
    }
}

.form-note {
    font-size: $font-xsmall;
    color: $gray-600;
    display: block;
    padding-top: $spacing-xsmall;
}
.form-success {
    color: $cb-green;
}

.rating-group {
    line-height: 1;
    display: flex;
    position: relative;
    font-size: $font-xxxlarge;
    letter-spacing: -1px;

    &:hover {
        .rating-group__label {
            color: $cb-orange;
        }
    }

    &__label {
        cursor: pointer;
        color: $cb-orange;
    }

    &__input {
        position: absolute !important;
        left: -9999px !important;

        &:checked ~ .rating-group__label {
            color: $gray-200;
        }

        &:hover ~ .rating-group__label {
            color: $gray-300;
        }
    }
}

.label-item {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid $gray-400;
    padding: $spacing-normal;
    padding-left: 40px;
    background: #fff;
    margin-bottom: $spacing-normal;
    cursor: pointer;

    &:hover {
        background: $gray-100;
        border-color: $gray-500;
    }

    input[type="radio"] {
        position: absolute;
        top: 19px;
        left: 15px;

        &:checked {
            + span {
                color: #000;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid $cb;
                    pointer-events: none;
                }
            }
        }
    }

    & + p {
        margin-bottom: 0;
    }

    &--delivery {
        input[type="radio"]:checked + span:after {
            content: "";
            background: url("/img/icn-delivery-address.svg") no-repeat center center / contain;
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            height: 60px;
            width: 60px;
            opacity: 0.3;
            transform: translateY(-50%);
        }
    }

    &--invoice {
        input[type="radio"]:checked + span:after {
            content: "";
            background: url("/img/icn-invoice-address.svg") no-repeat center center / contain;
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            height: 60px;
            width: 60px;
            opacity: 0.3;
            transform: translateY(-50%);
        }
    }
}

.label-group {
    label + label {
        margin-left: $spacing-large;
    }
}
