.main-nav {
    background: $cb-dark;
    // display: none;
    z-index: 4;
    // position: relative;

    @include media(">=large") {
        display: block;
    }

    .container {
        position: relative;
    }
}

.main-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;
    overflow: hidden;

    $this: &;

    @include media(">=large") {
        display: flex;

        li {
            margin-right: $spacing-xsmall;
        }
    }

    a {
        color: $cb-lightgray;
        padding: $spacing-small;
        display: flex;
        align-items: center;

        svg {
            margin-right: $spacing-small;
        }

        &:hover {
            color: #fff;
        }

        &#{$this}__products {
            color: $cb-dark;
            background: #fff;
            font-weight: 600;
            margin-top: $spacing-xsmall;
            padding: $spacing-xsmall $spacing-normal;

            &:hover,
            &.active,
            &.active:hover {
                margin-bottom: -$spacing-small;
                padding-bottom: $spacing-small;
                color: $cb !important;
            }
        }
    }
}

.product-menu-mask {
    @include media("<large") {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        position: fixed;
        left: 0;
        z-index: 9;
        top: 60px;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.8);
    }

    @include media(">medium") {
        top: 72px;
    }
}

.product-menu {
    position: absolute;
    display: flex;

    $this: &;

    @include media("<large") {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        left: 0;
        height: 100%;
        width: 300px;
        overflow: hidden;
        transform: translateX(-100%);
        flex-direction: column;
        background: #fff;
        z-index: 10;

        &.active {
            transform: none;
            box-shadow: 5px 2px 5px rgba(black, 0.5);
        }
    }

    @include media("<=medium") {
        top: 60px;
    }
    @include media(">medium") {
        top: 72px;
    }

    @include media(">=large") {
        width: 260px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
        top: 100%;
        // width: calc(100% - 100px);
        left: 50px;
        height: 430px;
        z-index: 1;
    }

    &.active {
        visibility: visible;
        opacity: 1;
        transform: none;
        transition: all 0.3s ease-in-out;
    }

    &__main {
        width: 100%;
        margin: 0;
        display: block;
        overflow: hidden;
        font-size: $font-small;
        position: relative;
        // height: 100%;
        background: #fff;
        // transition: all 0.3s ease-in-out;
        // box-shadow: 0 10px 10px rgba(black, 0.8);

        li {
            flex: 1;
            display: flex;

            &:first-child {
                a {
                    border-top: 0;
                }
            }

            &:last-child {
                a {
                    border-bottom: 0;
                }
            }
        }

        a {
            width: 100%;
            padding: $spacing-xsmall $spacing-small;
            position: relative;
            display: flex;
            align-items: center;
            color: $color-text;
            // background: #fff;

            svg {
                margin-right: $spacing-small;
                width: 24px;
                height: 24px;
            }
        }

        @include media("<large") {
            // padding: $spacing-normal;

            li {
                border-bottom: 1px solid $gray-300;
            }

            a {
                height: 40px;
                padding: 0;
                padding-left: $spacing-normal;

                // &:after {
                //     content: "";
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     width: 40px;
                //     height: 40px;
                //     margin-left: auto;
                //     flex-shrink: 0;
                //     content: "\276F";
                // }
            }
        }
        @include media(">=large") {
            box-shadow: 0 0 10px $gray-300;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 260px;
            font-weight: 600;

            &--mobile {
                display: none;
            }

            a {
                &:hover,
                &.active {
                    color: $cb;
                    box-shadow: 0 0 10px $gray-200;
                    border-color: $gray-300;
                    z-index: 3;
                    background: #fff;
                }
            }

            &:hover {
                a:not(.active) {
                    background: $gray-100;
                }
            }

            &.active {
                background: $gray-100;
            }
        }
    }

    &__main-arrow {
        margin-left: auto;
        width: 40px;
        height: 40px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $gray-200;

        &:active,
        &:focus {
            background-color: $gray-300;
        }

        .svg-icon {
            transform: rotate(90deg);
            width: 16px;
            height: 16px;
            object-fit: contain;
            object-position: center;

            path {
                fill: #000;
            }
        }

        @include media(">=large") {
            display: none;
        }
    }

    &__expanded {
        z-index: 2;
        background: #fff;
        transition: all 0.3s ease-in-out;

        @include media("<large") {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100%;
            transform: translateX(-100%);

            &.active {
                transform: none;
            }
        }

        @include media(">=large") {
            visibility: hidden;
            opacity: 0;
            display: block;
            width: 940px;
            position: absolute;
            top: 0;
            height: 100%;
            left: 260px;
            box-shadow: 0 0 10px $gray-300;
            transform: translateX(-20px);
            overflow-y: auto;

            &.active {
                visibility: visible;
                opacity: 1;
                transform: none;
            }
        }
    }

    &__submenu {
        font-size: $font-small;
        width: 100%;
        height: 100%;
        padding: $spacing-normal;
        position: relative;
        overflow-y: auto;

        $this: &;

        &.active {
            display: block;
        }

        a {
            color: $color-text;

            &:hover {
                color: $cb;
            }
        }

        > ul > li > a {
            font-weight: bold;
        }

        @include media("<large") {
            display: none;
            overflow-y: auto;
            background: #fff;
            padding: 0;
            padding-bottom: 150px;

            ul {
                margin: 0;
            }

            > ul {
                margin: 0;

                > li {
                    border-bottom: 1px solid $gray-300;
                    display: flex;
                    flex-wrap: wrap;

                    ul {
                        display: none;
                        border-top: 1px solid $gray-300;
                        width: 100%;
                        background: $gray-100;
                        padding-left: $spacing-small;
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                height: 40px;
                padding: 0;
                padding-left: $spacing-normal;
                width: calc(100% - 40px);

                &#{$this}-banner {
                    display: none;
                }
            }
        }

        @include media(">=large") {
            column-count: 4;
            column-gap: $spacing-normal;
            // column-width: 100px;
            column-fill: auto;
            display: none;
            font-size: 13px;
            // font-size: $font-xsmall;

            ul {
                break-inside: avoid;

                > li {
                    break-inside: avoid;

                    & + li {
                        margin-bottom: $spacing-normal;
                    }

                    > ul {
                        margin-bottom: $spacing-xsmall;
                        margin-bottom: 0;
                        margin-top: $spacing-xsmall;
                        // border-left: 1px solid red;

                        > li {
                            margin-bottom: $spacing-xsmall;
                        }
                    }
                }
            }

            a {
                &#{$this}-banner {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;

                    img {
                        width: 100%;
                        max-width: 230px;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }

        &--has-banner {
            @include media(">=large") {
                padding-right: 260px;
                column-count: 3;
            }
        }
    }

    &__submenu-arrow {
        margin-left: auto;
        width: 40px;
        height: 40px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $gray-200;

        &:active,
        &:focus {
            background-color: $gray-300;
        }

        .svg-icon {
            transform: scaleY(-1);
            width: 16px;
            height: 16px;
            object-fit: contain;
            object-position: center;

            path {
                fill: #000;
            }
        }

        &.active {
            transform: scaleY(-1);
        }

        @include media(">=large") {
            display: none;
        }
    }

    &__submenu-back {
        display: flex;
        align-items: center;

        .svg-icon {
            margin-right: $spacing-small;
            width: 16px;
            height: 16px;
            transform: rotate(-90deg);
        }

        @include media(">=large") {
            display: none;
        }
    }
}

.mobile-menu-trigger {
    flex-shrink: 0;
    display: block;
    width: 30px;
    height: 40px;
    position: relative;
    margin-left: $spacing-xsmall;

    @include media(">=large") {
        display: none;
    }

    span {
        display: block;
        width: 100%;
        height: 4px;
        background: $cb;
        position: absolute;
        top: 50%;
        left: 0px;
        transition: all 0.2s ease;
        transform-origin: center center;

        &:nth-child(1) {
            margin-top: -8px;
        }

        &:nth-child(3) {
            margin-top: 8px;
        }
    }

    &.active {
        span:nth-child(1) {
            transform: rotate(-45deg);
            margin-top: 0;
        }
        span:nth-child(2) {
            opacity: 0;
        }
        span:nth-child(3) {
            transform: rotate(45deg);
            margin-top: 0;
        }
    }
}

.homepage {
    .product-menu {
        @include media(">=large") {
            display: flex;
            visibility: visible;
            opacity: 1;
            transform: none;
        }
    }
}

// .product-menu__expanded {
//     opacity: 1;
//     visibility: visible;
//     transform: none;
// }

// [data-submenu="1"] {
//     display: block;
// }
