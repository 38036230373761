html,
body {
    height: 100vh;
}

a {
    color: $cb;
    text-decoration: none;
}

.container {
    margin: auto;
    max-width: calc(1200px + 100px);
    padding-left: $spacing-small;
    padding-right: $spacing-small;

    .row {
        margin-left: -#{$spacing-small};
        margin-right: -#{$spacing-small};
    }

    @include media(">medium") {
        padding-left: 50px;
        padding-right: 50px;

        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

.overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    z-index: 999;

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: $border-radius;
        padding: $spacing-large;
        box-shadow: 0 0.8px 1.8px rgba(0, 0, 0, 0.037), 0 1.9px 4.3px rgba(0, 0, 0, 0.053), 0 3.6px 8.1px rgba(0, 0, 0, 0.065), 0 6.5px 14.5px rgba(0, 0, 0, 0.077), 0 12.1px 27.2px rgba(0, 0, 0, 0.093), 0 29px 65px rgba(0, 0, 0, 0.13);
    }

    &__spinner {
        border: 5px solid $gray-200;
        border-top: 5px solid $cb;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: $spacing-large;
        animation: spin 2s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
body.has-overlay {
    height: 100vh;
    overflow: hidden;

    .overlay {
        transition: all 0.2s ease;
        opacity: 1;
        visibility: visible;
    }
}

body.mobile-menu-open {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .product-menu-mask {
        visibility: visible;
        opacity: 1;
    }
}

p,
ul,
ol,
form,
table {
    margin-top: 0;
    margin-bottom: $spacing-normal;
}

img {
    // display: block;
    max-width: 100%;
    height: auto;
}

sup {
    font-size: 65%;
    line-height: 0;
    position: relative;
    margin-left: 0.1em;
    vertical-align: super;
    // top: -50%;
}

.centered-banner {
    padding: $spacing-xlarge 0;
    display: flex;
    justify-content: center;
}

.section {
    padding: $spacing-small 0;

    @include media(">=medium") {
        padding: $spacing-xlarge 0;
    }

    &--gray {
        background: $gray-100;
    }
}
.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.content-zone {
    flex: 1 1 auto;
    // overflow: hidden;

    &--gray {
        background: #f0f0f0;
    }
}
.icon {
    width: 16px;
    height: 16px;
    display: flex;

    svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.simple-page {
    .header,
    .main-nav,
    .footer {
        display: none;
    }

    .content-zone {
        height: 100%;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.content-box {
    display: block;
    padding: $spacing-normal;
    border: 1px solid $gray-300;
    margin-bottom: $spacing-large;
    background: $gray-100;

    .content-box {
        background: #fff;
    }
}

.disclaimer {
    border: 1px solid lighten($cb, 10%);
    line-height: 1.2;
    padding: $spacing-normal;
    font-size: $font-small;
    background: $gray-100;
    max-width: 1000px;
    margin: auto;
    margin-bottom: $spacing-xlarge;

    @include media(">medium") {
        padding: $spacing-large;
    }
}

.js-is-mobile {
    display: block;

    @include media(">medium") {
        display: none;
    }
}
.js-is-mobile-menu {
    display: block;

    @include media(">=large") {
        display: none;
    }
}

.list-inline {
    display: flex;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    li:not(:first-child) {
        margin-right: $spacing-small;
    }
}

hr {
    background: transparent;
    height: 0;
    overflow: hidden;
    margin: $spacing-normal 0;
    border: 0;
    border-bottom: 1px solid $gray-400;
}

.small-only {
    display: block;

    @include media(">medium") {
        display: none;
    }
}

.desktop-only {
    display: none;

    @include media(">medium") {
        display: block;
    }
}

.jBox-Notice-green {
    background: $cb-green;
}
