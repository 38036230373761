body {
    font-size: 16px;
    color: $color-text;
    line-height: 1.4;
    font-family: $font-family-main;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $gray-800;
    margin: 0;
    line-height: 1.2;
}

$font-small: 14px;
$font-normal: 16px;
$font-large: 18px;
$font-xlarge: 24px;
$font-xxlarge: 32px;
$font-xxxlarge: 48px;

h1,
.h1 {
    font-weight: normal;
    font-size: $font-xlarge;
    margin-bottom: $spacing-large;
    line-height: 1;

    @include media(">medium") {
        font-size: $font-xlarge;
        margin-bottom: $spacing-large;
    }
    @include media(">large") {
        font-size: $font-xxlarge;
    }
}

h2,
.h2 {
    font-size: $font-xlarge;
    font-weight: 500;
    margin-bottom: $spacing-normal;
}

h3,
.h3 {
    font-size: $font-large;
    font-weight: 600;
    margin-bottom: $spacing-normal;
}

h4,
.h4 {
    font-size: $font-normal;
    font-weight: 600;
    margin-bottom: $spacing-small;
}

h5,
.h5 {
    font-size: $font-small;
    font-weight: 600;
}

.text-small {
    font-size: $font-small;
}

.text-xsmall {
    font-size: $font-xsmall;
}

.text-xxsmall {
    font-size: $font-xxsmall;
}

.text-large {
    font-size: $font-large !important;
}
.text-xlarge {
    font-size: $font-xlarge !important;
}
.text-xxlarge {
    font-size: $font-xxlarge !important;
}
.text-xxxlarge {
    font-size: $font-xxxlarge !important;
}

.text-danger {
    color: $cb;
}
.text-success {
    color: $cb-green;
}
.text-warning {
    color: $cb-orange;
}

.bullet-list {
    list-style-type: none;
    padding: 0;

    li {
        padding: $spacing-small 0;
        border-bottom: 1px solid $gray-200;
    }
}

.text-bold {
    font-weight: bold;
}