.tag-list {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-small;
    margin-bottom: $spacing-large;

    a {
        display: flex;
        padding: 5px 10px;
        background: $gray-100;
        color: $gray-700;
        border: 1px solid $gray-300;
        border-radius: $border-radius;
        margin: 0 $spacing-small $spacing-small 0;
        text-decoration: none;
        transition: all 0.25s;

        &:hover {
            background: $gray-200;
            border-color: $gray-400;
        }

        &:active,
        &:focus {
            background: $color-text;
            border-color: $color-text;
            color: #fff;
        }
    }
}
