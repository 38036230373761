.product-reviews {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $spacing-small;
}

.product-rating {
    display: inline-flex;
    align-items: center;

    + p > .btn {
        margin-top: $spacing-normal;
    }

    &__bg {
        margin-left: -2px;
        line-height: 1;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        margin-right: $spacing-small;
        font-size: $font-small;

        &:before {
            display: inline-block;
            content: "\2605\2605\2605\2605\2605";
            color: $gray-300;
        }
    }
    &__stars {
        overflow: hidden;
        color: $cb-orange;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        font-size: $font-small;

        &:before {
            display: inline-block;
            content: "\2605\2605\2605\2605\2605";
        }
    }

    &__num {
        display: inline-block;
        margin-right: $spacing-small;
        font-size: $font-xsmall;
    }

    &__reviews {
        display: block;
        font-size: $font-xsmall;
    }

    &__details {
        width: 100%;
        margin-bottom: 0;

        a {
            color: $color-text;
        }
    }
}
