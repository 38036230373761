// Fonts
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

@import "~jbox/dist/jBox.all.min.css";

// Abstract
@import "abstracts/mixins";
@import "abstracts/variables";

// Base
@import "base/reset";
@import "base/layout";
@import "base/typography";
@import "base/forms";
@import "base/buttons";

// Layout
@import "layout/header";
@import "layout/footer";

// Components
@import "components/breadcrumb";
@import "components/become-partner";
@import "components/newsletter";
@import "components/main-menu";
@import "components/pagination";
@import "components/product-price";
@import "components/product-reviews";
@import "components/product-options";
@import "components/avatar";
@import "components/rangeslider";
@import "components/taglist";
@import "components/footer";
@import "components/aside-box";

// Pages
@import "pages/homepage";
@import "pages/category";
@import "pages/product";
@import "pages/cart";
@import "pages/myaccount";
@import "pages/auth";
@import "pages/blog";
@import "pages/vreausavand";
@import "pages/error-page";

@import "base/utilities";
// debug
// *,
// *:before,
// *:after {
//     border: 1px solid red;
// }
