.footer {
    background: $cb-gray;
    border-top: 5px solid $cb-dark;
    color: #fff;
    padding: $spacing-large 0;

    @include media(">=medium") {
        padding: $spacing-xxlarge 0;
    }
}

.footer-menu {
    &__title {
        color: #fff;
    }
    ul {
        display: none;
        list-style-type: none;
        padding: 0;
        font-size: $font-small;
        padding-left: $spacing-normal;

        &.active {
            display: block;
            padding-top: $spacing-normal;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100px;
                height: 3px;
                background: $cb-green;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    li {
        margin-bottom: $spacing-xsmall;
    }

    a {
        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }

    h4 {
        color: #fff;
        position: relative;
    }

    @include media(">=medium") {
        display: flex;
        justify-content: space-between;

        ul {
            display: block;
            padding-left: 0;
        }

        h4 {
            padding-bottom: $spacing-small;
            margin-bottom: $spacing-large;

            &:after {
                content: "";
                display: block;
                width: 100px;
                height: 3px;
                background: $cb-green;
                position: absolute;
                left: 0;
                top: 100%;
            }
        }
    }

    .footer-menu-item {
        margin-bottom: $spacing-normal;

        @include media(">=medium") {
            width: calc(100% / 4 - #{$spacing-normal});
            margin-bottom: 0;
        }
    }
}

.footer-final-row {
    display: flex;
    flex-direction: column-reverse;

    p {
        font-size: $font-xsmall;
        margin: 0;
    }

    @include media(">=medium") {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.footer-social {
    list-style-type: none;
    margin: $spacing-normal 0;
    padding-left: 0;
    display: flex;

    li {
        margin: 0 $spacing-normal $spacing-small 0;
    }
}
