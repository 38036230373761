.product-header {
    padding-bottom: $spacing-small;
    $this: &;

    &__left {
        flex: 1;
        padding-bottom: $spacing-small;
    }

    &__right {
    }

    @include media(">medium") {
        display: flex;
        align-items: center;
        padding-top: 0;

        &__left {
            padding-bottom: 0;
        }

        &__right {
            width: 120px;
            flex-shrink: 0;
        }
    }
}

.product-brand {
    img,
    svg {
        display: block;
        width: 100%;
        max-width: 100px;
        max-height: 40px;
        object-fit: contain;
        height: auto;
        margin: 0;
    }

    @include media(">=medium") {
        padding-left: $spacing-normal;
        img,
        svg {
            max-width: 100px;
            max-height: 80px;
            max-width: 100%;
        }
    }
}

.product-title {
    margin-bottom: 0;
    // margin-bottom: $spacing-small;
    @include media("<medium") {
        padding-top: $spacing-small;
    }
}

.product-code {
    color: $gray-500;
    font-size: $font-small;
    margin-bottom: 0;
}

.product-main {
    border-top: 1px solid $gray-200;
    padding-bottom: $spacing-large;
    padding-top: $spacing-small;
    position: relative;

    @include media(">medium") {
        padding-top: $spacing-large;
        display: flex;
    }

    &__admin {
        line-height: 1;
        z-index: 1;
        position: absolute;
        top: $spacing-small;
        right: $spacing-small;

        a {
            display: block;
            padding: $spacing-small;
            background: #fff;
        }
    }
}

.product-flags {
    position: absolute;
    top: $spacing-normal;
    left: -#{$spacing-xsmall};
    z-index: 1;

    &:not(:empty) {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 4px 0;
            border-color: transparent #adb5bd transparent transparent;
        }
    }
}

.product-discount {
    background: $cb;
    color: #fff;
    padding: $spacing-xsmall;
    position: relative;
    display: block;
    width: fit-content;
}

.product__free-shipping {
    background: linear-gradient(to right, $cb-dark, $cb);
    color: #fff;
    padding: $spacing-xsmall $spacing-small;
    font-weight: 400;
    // font-size: $font-xsmall;
    text-transform: uppercase;
    display: block;
    width: fit-content;
    // margin-top: $spacing-xsmall;
}

.product-gallery {
    position: relative;
    &__main {
        overflow: hidden;
        position: relative;
        height: 0;
        padding-top: 100%;

        .tns-outer,
        > #main-gallery,
        .no-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > #main-gallery {
            display: flex;

            > div {
                display: flex;
            }
        }

        .no-img {
            object-fit: contain;
            object-position: center;
        }

        .tns-item {
            a,
            img {
                display: block;
            }

            a {
                width: 100%;
                height: 0;
                padding-top: 100%;
                position: relative;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: contain;
                object-position: center;
            }
        }
    }
    &__thumbs {
        margin: $spacing-normal 0;
        margin-bottom: $spacing-large;
        padding-left: $spacing-xlarge;
        padding-right: $spacing-xlarge;
        position: relative;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            display: flex;

            li {
                // padding: $spacing-xsmall;
                // max-width: 60px;
                // height: 0 !important;
                // padding-top: 100%;
                // position: relative;
                // display: flex !important;
                // overflow: hidden;

                img {
                    width: 60px;
                    height: 60px;
                    border: 2px solid $gray-300;
                    border-radius: $border-radius;
                    padding: $spacing-xsmall;
                    // height: 100%;
                    // width: 100%;
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    object-fit: contain;
                    object-position: center;
                }

                &.tns-nav-active {
                    img {
                        border: 2px solid $cb;
                    }
                }
            }
        }

        .tns-controls {
            button {
                display: block;
                overflow: hidden;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                overflow: hidden;
                text-indent: -99999px;
                width: 32px;
                height: 32px;
                border-radius: 32px;
                background: $gray-200;
                color: $gray-200;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.2s ease;
                border: none;
                z-index: 3;

                &:hover {
                    box-shadow: 0 0 5px rgba(black, 0.1);

                    &:before {
                        color: $gray-800;
                    }
                }

                &[disabled] {
                    opacity: 0.4;
                }

                &:active,
                &:focus {
                    outline: 0;
                }

                &:before {
                    content: "";
                    // display: block;
                    color: $color-text;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-indent: 0;
                    font-size: 18px;
                    font-weight: bold;
                    color: $gray-600;
                }

                &[data-controls="prev"] {
                    left: -#{$spacing-xsmall};

                    @include media(">medium") {
                        left: 0px;
                    }

                    &:before {
                        content: "\276E";
                    }
                }

                &[data-controls="next"] {
                    right: -#{$spacing-xsmall};

                    @include media(">medium") {
                        right: 0px;
                    }

                    &:before {
                        content: "\276F";
                        // border-left-color: transparent;
                        // border-bottom-color: transparent;
                        // transform: rotate(45deg);
                    }
                }
            }
        }
    }
    @include media(">medium") {
        width: 50%;
        flex-shrink: 0;
    }
    @include media(">large") {
        width: calc(100% / 5 * 2);
    }
}

.product-meta {
    &__left {
        flex: 1;
    }

    &__right {
        .product-stock {
            font-size: $font-large;
            margin-bottom: $spacing-normal;
        }
        .product-price__main {
            small {
                font-size: $font-normal;
                font-weight: 600;
            }
        }
    }

    .product-return-days {
        color: $cb;
        border: 1px solid $cb;
        text-align: center;
        margin: 5px 0;
        padding: 5px;
        width: fit-content;

        .h5 {
            color: $cb;
            font-weight: 400;
        }
    }

    @include media(">medium") {
        padding-left: $spacing-xlarge;
        padding-right: $spacing-normal;

        &__left {
            padding-right: $spacing-normal;
        }

        &__right {
            width: 240px;
            // width: calc(100% / 3);
        }
    }

    @include media(">=large") {
        display: flex;
        flex: 1;
    }
}
.product-actions {
    .btn {
        width: 100%;
        display: flex;
        font-size: $font-large;

        @include media("<medium") {
        }
    }
}

.product-stock {
    font-weight: 600;
    font-size: $font-small;

    &--yes {
        color: $cb-green;
    }

    &--order {
        color: $cb-orange;
    }

    &--no {
        color: $cb;
    }
}

.product-benefits {
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        svg {
            flex-shrink: 0;
            margin-right: 5px;
        }
    }
}

.product-nav {
    display: block;
    position: sticky;
    z-index: 2;
    top: 0;
    background: $cb-dark;
    padding: $spacing-small 0;
    margin-bottom: $spacing-xlarge;

    @include media("<large") {
        top: 60px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    li {
        margin-right: $spacing-normal;
    }
    a {
        color: #fff;
    }
}

.product-data {
    .section {

        @include media(">=medium") {
            padding-top: 0;
        }
    }
}

.product-text {
    max-width: 800px;
    font-size: $font-small;

    h3 {
        margin-top: $spacing-large;
        margin-bottom: $spacing-small;
    }
}

.product-specs {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;

    &__term {
        display: block;
        font-weight: 600;
    }

    li {
        padding: $spacing-xsmall;
        margin-bottom: $spacing-small;

        &:nth-child(odd) {
            background: $gray-100;
        }
    }

    @include media(">=medium") {
        li {
            display: flex;
            align-items: flex-start;
        }

        &__term {
            width: 250px;
            flex-shrink: 0;
        }
    }
}

.product-subtitle {
    color: #000;
    padding-bottom: $spacing-xsmall;
    border-bottom: 1px solid $gray-300;
    margin-bottom: $spacing-large;

    span {
        font-weight: normal;
        color: $gray-600;
    }

    &--has-action {
        @include media(">=medium") {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.reviews-header {
    width: 100%;
    align-items: center;
    border-bottom: 1px solid $gray-300;
    padding-bottom: $spacing-normal;
    margin-bottom: $spacing-large;

    &__big-review {
        text-align: center;
    }

    &__stats {
        flex-grow: 1;
        margin-bottom: $spacing-large;
        min-width: 330px;
        white-space: nowrap;
    }

    &__write {
        text-align: center;
    }

    @include media(">=medium") {
        display: flex;

        &__stats {
            width: 35%;
            padding: 0 $spacing-large;
            margin-bottom: 0;
        }

        &__write {
            text-align: left;
        }
    }
}

.review-bar {
    display: flex;
    align-items: center;

    $review-bar-height: 12px;

    @include media("<medium") {
        font-size: $font-small;
    }

    &__type {
        width: 80px;
        text-align: right;
    }

    &__progress {
        width: 100%;
        height: $review-bar-height;
        border-radius: $review-bar-height;
        background: $gray-300;
        position: relative;
        overflow: hidden;
        margin: 0 $spacing-normal;

        span {
            position: absolute;
            top: 0;
            left: 0;
            height: $review-bar-height;
            border-radius: $review-bar-height;
        }
        &--1star {
            span {
                background: $cb;
            }
        }
        &--2star {
            span {
                background: $cb-orange;
            }
        }
        &--3star {
            span {
                background: olive;
            }
        }
        &--4star {
            span {
                background: olivedrab;
            }
        }
        &--5star {
            span {
                background: $cb-green;
            }
        }
    }

    &__percentage {
        width: 80px;
        white-space: nowrap;
    }
}

.review-item {
    $this: &;

    & + & {
        border-top: 1px solid $gray-300;
        padding-top: $spacing-large;
    }

    &:last-of-type {
        border-bottom: 1px solid $gray-300;
        margin-bottom: $spacing-large;

        & & {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }

    @include media(">=medium") {
        display: flex;
        width: 100%;

        &__left {
            flex-shrink: 0;
            width: calc(100% / 5);
            padding-right: $spacing-small;
            padding-bottom: $spacing-small;
        }
    }

    &__status {
        line-height: 1;
        font-size: 14px;
        background: $gray-200;
        padding: $spacing-small;
        margin-bottom: $spacing-normal;
        border-left: 2px solid $cb;

        strong {
            color: $cb;
        }
    }

    &__right {
        .product-rating {
            margin-bottom: $spacing-normal;
        }
    }
    &__author {
        line-height: 1.2;
    }

    &__product-image {
        padding-right: $spacing-normal;
        img {
            margin-bottom: $spacing-small;
        }
    }

    &__name {
        font-weight: bold;
    }

    &__date {
        color: $gray-600;
        font-size: $font-small;
    }

    &__gallery {
        list-style-type: none;
        padding: 0;
        display: flex;

        li {
            margin: 0 $spacing-small $spacing-small 0;
        }

        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            object-position: center;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding-left: 0;
        font-size: $font-small;
        line-height: 1;
        margin-bottom: $spacing-large;

        li {
            &:not(:last-child) {
                padding-right: $spacing-normal;
                border-right: 1px solid $gray-300;
                margin-right: $spacing-normal;
            }
        }

        a {
            display: flex;
            align-items: center;
            white-space: nowrap;

            img {
                margin-right: $spacing-xsmall;
            }
        }
    }

    &__comment {
        padding-top: $spacing-large;
        padding-bottom: $spacing-normal;
        border-top: 1px solid $gray-300;

        #{$this}__author {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-normal;

            .avatar {
                margin-right: $spacing-normal;
                width: 50px;
                height: 50px;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    &--question {
        flex-direction: column;

        & + & {
            border-top: 1px solid $gray-300;
            padding-top: $spacing-large;
        }

        & &:first-of-type {
            border-top: 1px solid $gray-300;
            padding-top: $spacing-large;
        }

        &:last-of-type {
            border-bottom: 1px solid $gray-300;
            margin-bottom: $spacing-large;

            & & {
                margin-bottom: 0;
                border-bottom: 0;
            }
        }

        @include media(">=medium") {
            #{$this}__author {
                display: flex;
                align-items: center;
                margin-bottom: $spacing-normal;

                .avatar {
                    margin-right: $spacing-normal;
                    width: 50px;
                    height: 50px;
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                }
            }

            #{$this}__question-body {
                font-size: $font-large;
                color: $gray-900;
                padding-left: calc(50px + #{$spacing-normal});
            }

            #{$this}__question-form {
                margin-bottom: $spacing-xlarge;
            }
        }
    }
}

.visited-products {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    overflow-x: auto;

    @include media(">=medium") {
        overflow-x: hidden;
        flex-wrap: wrap;
    }

    li {
        margin: 0 $spacing-small $spacing-small 0;
        flex-shrink: 0;
    }

    a {
        display: block;
        width: 80px;
        height: 0;
        padding-top: 100%;
        position: relative;
        background: #fff;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        display: block;
        transition: all 0.2s ease;
        border: $spacing-xsmall solid #fff;
        box-shadow: 1px 1px 3px rgba(black, 0.1);
    }

    a:hover {
        img {
            border-color: $cb;
        }
    }
}

.fancybox-is-open .fancybox-bg {
    background: #fff;
    opacity: 1;
}
.fancybox-show-thumbs {
    .fancybox-inner {
        right: 0;
    }
    .fancybox-thumbs {
        display: none;
    }
}

.fancybox-thumbs__list {
    a {
        width: 75px;
        height: 75px;
        background-size: contain;
        background-position: center center;
        position: relative;
        display: block;
    }
}
.fancybox-inner {
    .product-actions__buy {
        margin-bottom: 0;
        z-index: 99999;
        position: absolute;
        right: 20px;
        bottom: 20px;

        > span {
            display: none;
        }
    }
}

@include media(">large") {
    .fancybox-show-thumbs {
        .fancybox-inner {
            right: 0;
            left: 164px;
        }

        .fancybox-thumbs {
            display: block;
            right: auto;
            left: 0;
            width: 164px;
        }
    }
}

// .fancybox-thumbs {
//     right: auto;
//     left: 0;
// }

.jBox-title {
    font-weight: 600;
    font-size: $font-large;
}

.product-stickybar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 11;
    box-shadow: 5px 0 20px rgba(black, 0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);
    transition: all 0.3s ease;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    @include media("<medium") {
        display: none;
    }

    &__img,
    &__name {
        cursor: pointer;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: $spacing-normal 0;
    }

    &__price {
        margin-left: auto;
    }

    .product-reviews {
        font-size: 12px;
        margin-bottom: 0;
        @include media("<large") {
            display: none;
        }
    }

    .product-price {
        margin-bottom: 0;
        text-align: right;
        white-space: nowrap;

        &__main {
            font-size: $font-large;
        }
    }

    .btn--fav {
        padding: 0;
        min-height: 0;
        height: 36px;
    }

    &__buy {
        flex-grow: 0;
        max-width: 180px;
    }

    &__actions {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        .product-actions__buy {
            margin-bottom: $spacing-xsmall;
        }

        p {
            margin-bottom: 0;
            line-height: 1;
        }
    }
}

.product-free-delivery {
    display: flex;
    align-items: center;
    color: $cb-green;
    font-size: $font-small;

    svg {
        flex-direction: 0;
        margin-right: $spacing-xsmall;
    }
}

.product-unavailable {
    // line-height: 1;
    font-weight: 600;
    display: inline-block;
    background: $cb;
    color: #fff;
    font-size: $font-small;
    padding: $spacing-xsmall $spacing-small;
    border-radius: 3px;
}

.sticky-container {
    position: fixed;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    transition: opacity 0.2s;
    padding: 15px;
    background: white;
    display: none;
    z-index: 99;
    left: 0;

    @include media("<medium") {
        display: block;
    }

    .product-actions__buy {
        margin: 0;
    }

    .btn--cart {
        width: 100%;
    }

    .product-call {
        margin: 0;

        .btn {
            font-size: 16px;
            min-height: 36px;
            padding: 5px 16px;
        }

        .btn__icon  {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.u-hidden {
    opacity: 0;
    pointer-events: none;
}


