.product-options {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: $spacing-xsmall;
        margin-bottom: $spacing-normal;

        a {
            font-size: $font-small;
            color: $color-text;
            display: block;
            padding: $spacing-xsmall $spacing-small;
            border-radius: $border-radius;
            border: 1px solid $gray-500;
            margin: 0 $spacing-small $spacing-small 0;

            &:hover {
                transition: all 0.2s;
                color: $gray-900;
                border-color: $gray-700;
                box-shadow: 0 0 4px rgba(black, 0.2);
            }

            &:active,
            &:focus {
                border-color: $cb;
                box-shadow: inset 0 0 0 1px $cb;
            }

            &.disabled {
                color: $gray-400;
                border-color: $gray-400;

                &:hover {
                    border-color: $gray-700;
                }
            }

            &.selected {
                font-weight: 600;
                border-color: $cb;
                box-shadow: inset 0 0 0 1px $cb, inset 2px 2px 4px rgba(black, 0.2);
            }
        }
    }
}
