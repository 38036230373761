.avatar {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $cb;
    color: #fff;
    overflow: hidden;
    margin-bottom: $spacing-small;

    &__initials {
    }

    &__picture {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 100%;
        overflow: hidden;
    }

    &--big {
        font-size: $font-large;
    }

}
