.become-partner {
    position: relative;
    padding: $spacing-large;
    margin-bottom: $spacing-xlarge;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to right, rgba($cb, 0.8) 0%, rgba($cb-dark, 0.8) 100%);
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }

    &__text {
        z-index: 2;
        color: #fff;
        font-size: $font-large;
        max-width: 700px;
        line-height: 1.2;
        padding-bottom: $spacing-normal;

        p {
            margin-bottom: 0;
            max-width: 500px;
        }
    }

    &__title {
        color: currentcolor;
        margin-bottom: $spacing-small;
    }

    &__action {
        z-index: 2;
    }

    @include media(">=medium") {
        min-height: 200px;
        flex-direction: row;
        text-align: left;

        &__title {
            font-size: $font-xxxlarge;
        }

        &__text {
            padding-right: $spacing-normal;
            padding-bottom: 0;
        }
    }
}
